import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import layoutContext from './layoutContext'
import renderAst from './rehyped'
import SEO from './seo'

const IndexPage = ({ data, cult }) => {
  const {
    markdownRemark: {
      frontmatter: { title },
      htmlAst
    }
  } = data

  const { setCult } = useContext(layoutContext)
  setCult(cult)

  return (
    <>
      <SEO
        title={title}
        keywords={['money republic', 'micro loans', 'республика денег']}
        lang={cult}
      />
      {renderAst(htmlAst)}
    </>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
  cult: PropTypes.string
}

export default IndexPage
